<template>
  <NuxtImg
    v-if="isLazyLoad"
    :id="generateUID('image')"
    ref="imageX"
    :format="defaultExtension"
    class="lazyload"
    v-bind="{
      ...$attrs,
      src: imageUrl,
      alt: $attrs.alt || 'Image'
    }"
    :data-src="imageUrl"
    :data-expand="expand"
    :preload="preload"
    :loading="isLazyLoad ? 'lazy' : 'eager'"
  />

  <NuxtImg
    v-else
    :id="generateUID('image')"
    ref="imageX"
    :format="defaultExtension"
    v-bind="{
      ...$attrs,
      src: imageUrl,
      alt: $attrs.alt || 'Image'
    }"
    :data-expand="expand"
    :preload="preload"
  />
</template>

<script setup>
defineOptions({
  name: 'AtomsImage'
})

const props = defineProps({
  src: {
    type: [String, Object],
    required: true,
    default: ''
  },

  expand: {
    type: [String, Number],
    default: -20
  },

  preload: {
    type: Boolean,
    default: false
  }
})

const $attrs = useAttrs()

const imageX = ref()
const isLazyLoad = ref()

onMounted(() => {
  isLazyLoad.value = !isInViewport(imageX.value.$el)
})

const defaultExtension = ref('webp')
const IMAGE_EXTENSTION = computed(() => {
  let ext = ''
  let prefix = '?'

  if (props.src.includes('ctfassets')) {
    if (props.src.includes('?w=')) {
      prefix = '&'
    }

    ext = `${prefix}fm=${defaultExtension.value}`
  }

  return ext
})

const imageUrl = ref(`${props.src}${IMAGE_EXTENSTION.value}`)

onBeforeMount(async () => {
  try {
    if (!self?.createImageBitmap) {
      return
    }

    const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA='

    const blob = await fetch(webpData).then(r => r.blob())

    defaultExtension.value = await createImageBitmap(blob).then(() => 'webp', () => 'png')

    if (props.preload && window) {
      const image = document.createElement('link')
      image.rel = 'preload'
      image.as = 'image'
      image.href = imageUrl.value

      document.head.appendChild(image)
    }
  } catch (error) {
    throw new Error(error)
  }
})

</script>
